
import SDK from '../services';
import type { AxiosError } from 'axios';
import refreshTokenHandle from '~/utils/refresh.token';

export default defineNuxtPlugin(async (nuxtApp) => {
  const sdk = new SDK(nuxtApp.$config.public.apiEndpoint);

  sdk.$api.agent.interceptors.response.use((v) => v,
    (err: AxiosError) => refreshTokenHandle(err, sdk));


  return {
      provide: {
        sdk
      }
  }
});