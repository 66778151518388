/* eslint-disable consistent-return */
import SDK from '../services';
import Axios from 'axios';
import type { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';

export default async function (err: AxiosError, api: SDK): Promise<void | any> {
  const error = err.response;
  const url = err.request.responseURL;
  const router = useRouter();

  const ignoredRoutes = ['refresh_token', 'sign-in'];
  const refreshToken = Cookies.get('nprt');

  try {
    // Try to refresh the token and resend request.
    if (error?.status === 401 && !ignoredRoutes.includes(url?.split('/')?.pop())) {
      if (refreshToken) {
        // Update in axios instance
        api.$api.agent.setToken(refreshToken);
        const res = await api.$api.auth.refreshToken(refreshToken);

        api.$api.agent.setToken(res.access_token);

        // Update cookies
        Cookies.set('naat', res.access_token, { expires: dayjs().add(2, 'hour').toDate() });
        Cookies.set('nprt', res.refresh_token, { expires: dayjs().add(29, 'days').toDate() });

        // Retry original request
        error.config.headers.Authorization = `Bearer ${res.access_token}`;
        return Axios.request(err.config);
      }

      Cookies.remove('naat');
      Cookies.remove('nprt');
    }

    return Promise.reject(err);7
  } catch {
    Cookies.remove('naat');
    Cookies.remove('nprt');
    router.push('/sign_in');

    requestAnimationFrame(() => {
      Promise.reject(err);
    });
  }
}
