import { default as customerszW9MCEDY8JMeta } from "/opt/buildhome/repo/pages/customers.vue?macro=true";
import { default as garantees0R3ATuBGBHMeta } from "/opt/buildhome/repo/pages/garantees.vue?macro=true";
import { default as immobileskd5J8o9fDvMeta } from "/opt/buildhome/repo/pages/immobiles.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as lead_sourcesoDwQaxypIiMeta } from "/opt/buildhome/repo/pages/lead_sources.vue?macro=true";
import { default as nearby_alertnCuvk0UQ8MMeta } from "/opt/buildhome/repo/pages/nearby_alert.vue?macro=true";
import { default as rentalslItDQuXGCCMeta } from "/opt/buildhome/repo/pages/rentals.vue?macro=true";
import { default as _360_45viewqvpIXWVcg9Meta } from "/opt/buildhome/repo/pages/reports/360-view.vue?macro=true";
import { default as by_45userYcsThHZ6iTMeta } from "/opt/buildhome/repo/pages/reports/by-user.vue?macro=true";
import { default as indexGevHmlQqv9Meta } from "/opt/buildhome/repo/pages/reports/index.vue?macro=true";
import { default as passwordoSgjpjA0jZMeta } from "/opt/buildhome/repo/pages/settings/password.vue?macro=true";
import { default as preferences9w8KFHzMCDMeta } from "/opt/buildhome/repo/pages/settings/preferences.vue?macro=true";
import { default as profilepaxLzrYNK7Meta } from "/opt/buildhome/repo/pages/settings/profile.vue?macro=true";
import { default as sign_inech4x0d7MFMeta } from "/opt/buildhome/repo/pages/sign_in.vue?macro=true";
import { default as usersSKkXId5hNDMeta } from "/opt/buildhome/repo/pages/users.vue?macro=true";
export default [
  {
    name: customerszW9MCEDY8JMeta?.name ?? "customers___br",
    path: customerszW9MCEDY8JMeta?.path ?? "/customers",
    meta: customerszW9MCEDY8JMeta || {},
    alias: customerszW9MCEDY8JMeta?.alias || [],
    redirect: customerszW9MCEDY8JMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/customers.vue").then(m => m.default || m)
  },
  {
    name: garantees0R3ATuBGBHMeta?.name ?? "garantees___br",
    path: garantees0R3ATuBGBHMeta?.path ?? "/garantees",
    meta: garantees0R3ATuBGBHMeta || {},
    alias: garantees0R3ATuBGBHMeta?.alias || [],
    redirect: garantees0R3ATuBGBHMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/garantees.vue").then(m => m.default || m)
  },
  {
    name: immobileskd5J8o9fDvMeta?.name ?? "immobiles___br",
    path: immobileskd5J8o9fDvMeta?.path ?? "/immobiles",
    meta: immobileskd5J8o9fDvMeta || {},
    alias: immobileskd5J8o9fDvMeta?.alias || [],
    redirect: immobileskd5J8o9fDvMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/immobiles.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index___br",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: lead_sourcesoDwQaxypIiMeta?.name ?? "lead_sources___br",
    path: lead_sourcesoDwQaxypIiMeta?.path ?? "/lead_sources",
    meta: lead_sourcesoDwQaxypIiMeta || {},
    alias: lead_sourcesoDwQaxypIiMeta?.alias || [],
    redirect: lead_sourcesoDwQaxypIiMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/lead_sources.vue").then(m => m.default || m)
  },
  {
    name: nearby_alertnCuvk0UQ8MMeta?.name ?? "nearby_alert___br",
    path: nearby_alertnCuvk0UQ8MMeta?.path ?? "/nearby_alert",
    meta: nearby_alertnCuvk0UQ8MMeta || {},
    alias: nearby_alertnCuvk0UQ8MMeta?.alias || [],
    redirect: nearby_alertnCuvk0UQ8MMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/nearby_alert.vue").then(m => m.default || m)
  },
  {
    name: rentalslItDQuXGCCMeta?.name ?? "rentals___br",
    path: rentalslItDQuXGCCMeta?.path ?? "/rentals",
    meta: rentalslItDQuXGCCMeta || {},
    alias: rentalslItDQuXGCCMeta?.alias || [],
    redirect: rentalslItDQuXGCCMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/rentals.vue").then(m => m.default || m)
  },
  {
    name: _360_45viewqvpIXWVcg9Meta?.name ?? "reports-360-view___br",
    path: _360_45viewqvpIXWVcg9Meta?.path ?? "/reports/360-view",
    meta: _360_45viewqvpIXWVcg9Meta || {},
    alias: _360_45viewqvpIXWVcg9Meta?.alias || [],
    redirect: _360_45viewqvpIXWVcg9Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/reports/360-view.vue").then(m => m.default || m)
  },
  {
    name: by_45userYcsThHZ6iTMeta?.name ?? "reports-by-user___br",
    path: by_45userYcsThHZ6iTMeta?.path ?? "/reports/by-user",
    meta: by_45userYcsThHZ6iTMeta || {},
    alias: by_45userYcsThHZ6iTMeta?.alias || [],
    redirect: by_45userYcsThHZ6iTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/reports/by-user.vue").then(m => m.default || m)
  },
  {
    name: indexGevHmlQqv9Meta?.name ?? "reports___br",
    path: indexGevHmlQqv9Meta?.path ?? "/reports",
    meta: indexGevHmlQqv9Meta || {},
    alias: indexGevHmlQqv9Meta?.alias || [],
    redirect: indexGevHmlQqv9Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/reports/index.vue").then(m => m.default || m)
  },
  {
    name: passwordoSgjpjA0jZMeta?.name ?? "settings-password___br",
    path: passwordoSgjpjA0jZMeta?.path ?? "/settings/password",
    meta: passwordoSgjpjA0jZMeta || {},
    alias: passwordoSgjpjA0jZMeta?.alias || [],
    redirect: passwordoSgjpjA0jZMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: preferences9w8KFHzMCDMeta?.name ?? "settings-preferences___br",
    path: preferences9w8KFHzMCDMeta?.path ?? "/settings/preferences",
    meta: preferences9w8KFHzMCDMeta || {},
    alias: preferences9w8KFHzMCDMeta?.alias || [],
    redirect: preferences9w8KFHzMCDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/preferences.vue").then(m => m.default || m)
  },
  {
    name: profilepaxLzrYNK7Meta?.name ?? "settings-profile___br",
    path: profilepaxLzrYNK7Meta?.path ?? "/settings/profile",
    meta: profilepaxLzrYNK7Meta || {},
    alias: profilepaxLzrYNK7Meta?.alias || [],
    redirect: profilepaxLzrYNK7Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: sign_inech4x0d7MFMeta?.name ?? "sign_in___br",
    path: sign_inech4x0d7MFMeta?.path ?? "/sign_in",
    meta: sign_inech4x0d7MFMeta || {},
    alias: sign_inech4x0d7MFMeta?.alias || [],
    redirect: sign_inech4x0d7MFMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/sign_in.vue").then(m => m.default || m)
  },
  {
    name: usersSKkXId5hNDMeta?.name ?? "users___br",
    path: usersSKkXId5hNDMeta?.path ?? "/users",
    meta: usersSKkXId5hNDMeta || {},
    alias: usersSKkXId5hNDMeta?.alias || [],
    redirect: usersSKkXId5hNDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/users.vue").then(m => m.default || m)
  }
]